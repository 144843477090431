<script>
import arrayToSubArray from "@/components/blocks/render/arrayToSubArray";

export default {
  name: "InnerNewsRenderBlock",
  functional: true,
  props: {
    // see file typeOfParse params for "blocks" array
    blocks: {
      type: Array,
      default: null,
      require: true,
    },
  },
  render(createElement, context) {
    const blocks = context.props.blocks;
    return createElement(
      "div",
      arrayToSubArray(blocks, "image", createElement)
    );
  },
};
</script>

<style lang="scss" scoped>
.inner-news {
  &__image {
    width: 100%;
    display: block;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__content {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.03em;
    color: #18181b;
  }
}
</style>
