<script>
import formatD from "@/mixins/formatD";

export default {
  name: "UiFormateDate",
  functional: true,
  props: {
    date: {
      type: Number,
      default: null,
      require: true,
    },
    format: {
      type: String,
      default: "dd.MM.yyyy",
    },
  },
  render(createElement, context) {
    return context.props.date != null
      ? createElement(
          "span",
          {
            class: {
              date: true,
            },
          },
          formatD(context.props.date, context.props.format)
        )
      : "";
  },
};
</script>

<style lang="scss" scoped>
.date {
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.05em;
  color: #18181b;
  opacity: 0.7;
}
</style>
