var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pub-quote" }, [
    _c("div", { staticClass: "pub-quote__text" }, [
      _vm._v(" " + _vm._s(_vm.quoteData.text) + " "),
    ]),
    _c("div", { staticClass: "pub-quote__caption" }, [
      _vm._v(" " + _vm._s(_vm.quoteData.caption) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }