import humanFileSize from "@/mixins/humanFileSize";
import replLocalToApi from "@/mixins/replLocalToApi";
import UIQuote from "@/components/ui/UIQuote";
import ImageGalleryBlock from "@/components/blocks/ImageGalleryBlock";
/**
 * typeOfParse(type, block.data)
 * Возвращает созданый элементы в зависимости от типа
 */
export default function typeOfParse(type, data, createElement) {
  // paragraph
  if (type === "paragraph") {
    return createElement("p", {
      domProps: {
        innerHTML: data.text,
      },
    });
  }

  // quote
  if (type === "quote") {
    return createElement(UIQuote, {
      props: {
        type: type,
        quoteData: {
          text: data.text,
          caption: data.caption,
          alignment: data.alignment,
        },
      },
    });
  }

  // attachment
  if (type === "attachment") {
    return createElement("div", [
      createElement(
        "a",
        {
          attrs: {
            href: data.source,
            title: data.title,
            "data-created": data.created_at,
          },
        },
        data.title
      ),
      createElement("span", " (" + humanFileSize(data.size, true) + ")"),
    ]);
  }

  // table
  if (type === "table") {
    const table = []; // Основной массив для таблицы
    data.content.forEach((tr) => {
      const row = []; // Строки таблицы
      tr.forEach((td) => {
        row.push(createElement("td", td));
      });
      table.push(createElement("tr", row));
    });

    return createElement("table", table);
  }

  // header
  if (type === "header") {
    // TODO: data.level = min: 1, max: 6 - добавить проверку
    return createElement("h" + data.level, data.text);
  }

  // list
  if (type === "list") {
    /**
    data.style === unordered
      list-style-type: disc
    data.style === ordered
      list-style-type: decimal
  */
    const style = data.style;
    const listStyleType = style === "unordered" ? "disc" : "decimal"; // Тип списка
    const list = []; // Промежуточный массив для элементов списка
    data.items.forEach((li) => {
      list.push(createElement("li", li));
    });

    return createElement(
      "ul",
      {
        style: {
          listStyleType: listStyleType,
        },
      },
      list
    );
  }

  // img
  if (type === "img") {
    const img = data[0];
    return createElement("img", {
      attrs: {
        alt: img.alt,
        src: replLocalToApi(img.source),
      },
    });
  }

  // TODO: added picture

  // gallery
  if (type === "gallery") {
    const gallery = []; // Промежуточный массив для галлереи
    data.forEach((galleryItem) => {
      /**
      [
        {
          image: String,
          category: String,
          description: String
        },
        ...
      ]
      */
      // TODO: add category in backend admin
      gallery.push({
        image: replLocalToApi(galleryItem.image_thumb),
        category: "static", // String
        description: galleryItem.caption,
      });
    });
    return createElement(ImageGalleryBlock, {
      props: {
        hideTabs: true,
        hidePageTitle: true,
        images: gallery,
      },
    });
  }

  // TODO: add link
  // link
  // if (type === 'link') {
  //   return createElement('div', [
  //     createElement('a',
  //       {
  //         attrs: {
  //           href: data.meta,
  //           title: data.link
  //         }
  //       },
  //       data.link
  //     )
  //   ])
  // }
  return null;
}

/**
 *

{
  type: 'paragraph',
  data: {
    text: String
  }
}

// TODO:  quote alignment only 'left' STATIC
{
  type: 'quote',
  data: {
    text: String,
    caption: String,
    alignment: String
  }
}

{
  type: 'image',
  data: [
    {
      id: Number,
      source: String,
      image_thumb: String,
      image_desktop: Boolean,
      image_tablet: Boolean,
      image_mobile: Boolean,
      original_name: String,
      alt: String
    },
    ...
  ]
}

{
  type: 'list',
  data: {
    style: 'ordered' || 'unordered',
    items: Array[String]
  }
}

{
  type: 'header',
  data: {
    text: String,
    level: Number // min: 1, max: 6
  }
}

// TODO: add personality render block
{
  type: 'personality',
  data: {
    name: String,
    description: String,
    link: String
  }
}

{
  type: 'table',
  data: {
    content: [ [String], [String] ]
  }
}

{
  type: 'attachment',
  data: {
    id: Number,
    source: Sting,
    original_name: String,
    size: Number,
    created_at: Number,
    title: String
  }
}

{
  type: 'link',
  data: {
    link: 'tet',
    meta: []
  }
}

  *
  */
