import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import typeOfParse from "@/components/blocks/render/typeOfParse";
/**
 * Ищет элемент в массиве и все что до него оборачивает в массив
 * И создает элементы по типу
 * @param obj Object - Объект над кооторым происходям все действия
 * @param el String - Элемент который необходимо найти
 * @param createElement - функция создания элемента
 * // TODO: Тестировать
 */
export default function arrayToSubArray(obj, el, createElement) {
  const output = []; // Итоговый массив

  let wrap = []; // Временная обертка
  for (const key in obj) {
    if (obj[key].type === el) {
      let inWrap = []; // дополнительная обертка
      wrap.forEach((element) => {
        if (element.type === "image") {
          inWrap.push(typeOfParse("img", element.data, createElement));
        }
        inWrap.push(typeOfParse(element.type, element.data, createElement));
      });
      // Изображение или галлерея
      // Если изображений больше 1 то, это галлерея
      if (obj[key].type === "image" && obj[key].data.length > 1) {
        output.push(
          createElement(
            ContentWrapperBlock,
            {
              class: {
                "inner-news__content": true,
              },
            },
            inWrap
          )
        );
        output.push(typeOfParse("gallery", obj[key].data, createElement));
        wrap = []; // clear
        inWrap = []; // clear
      } else {
        wrap.push(obj[key]);
      }
    } else {
      wrap.push(obj[key]);
    }
  }

  // Создаем последние элементы по типу
  if (wrap.length > 0) {
    let inWrap = [];
    wrap.forEach((element) => {
      if (element.type === "image") {
        inWrap.push(typeOfParse("img", element.data, createElement));
      }
      inWrap.push(typeOfParse(element.type, element.data, createElement));
    });
    output.push(
      createElement(
        ContentWrapperBlock,
        {
          class: {
            "inner-news__content": true,
          },
        },
        inWrap
      )
    );
    inWrap = []; // clear
    wrap = []; // clear
  }
  return output;
}
