<template>
  <div class="inner-news-page">
    <page-header
      light
      no-padding
      dark-crumb
      class="inner-news-page__header"
      :title="newsTitle || ''"
      :link-to="linkTo"
    >
      <template #top>
        <ui-formate-date :date="newsTime" />
      </template>
    </page-header>
    <!-- <content-wrapper-block>
    <iframe src="https://www.youtube.com/embed/2I4mBEgct_4" align="left">
        Ваш браузер не поддерживает плавающие фреймы!
    </iframe>
  </content-wrapper-block> -->
    <content-wrapper-block v-if="newsVideo">
      <iframe :src="newsVideo" align="left">
        Ваш браузер не поддерживает плавающие фреймы!
      </iframe>
    </content-wrapper-block>
    <div class="inner-news__content-wrapper">
      <img :src="newsPreview" />
    </div>
    <inner-news-render-block :blocks="newsBlocks" />
    <best-apartments-block />
    <slider-news title="Похожие новости" :navigation="true" />
    <callback-block id="callback" id-formblock="question-block__callback" />
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageHeader from "@/components/elements/PageHeader";
import CallbackBlock from "@/components/blocks/CallbackBlock";
import BestApartmentsBlock from "@/components/blocks/BestApartmentsBlock";
import SliderNews from "@/components/blocks/SliderNews";
import UiFormateDate from "@/components/ui/render/UIFormateDate";
import InnerNewsRenderBlock from "@/components/blocks/render/InnerNewsRenderBlock";
import { mapMutations } from "vuex";
import axios from "axios";
// TODO: Хлебные крошки
export default {
  name: "InnerNewsPage",
  components: {
    PageHeader,
    UiFormateDate,
    CallbackBlock,
    BestApartmentsBlock,
    SliderNews,
    InnerNewsRenderBlock,
    ContentWrapperBlock,
  },
  data: () => ({
    onePublication: null,
    newsBlocks: null,
    newsTitle: null,
    newsPreview: null,
    newsTime: null,
    newsVideo: ""
  }),
  computed: {
    linkTo() {
      const { name } = this.$route;
      return name === "InnerNewsPage"
        ? { name: "NewsCatalogPage" }
        : { name: "ProgressPage" };
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.$nextTick(() => {
      this.loadPublication(this.$route.params.slug);
    });
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadPublication(vm.$route.params.slug);
    });
  },
  destroyed() {
    this.content = null;
  },
  // created () {
  //   this.loadPublication(this.$route.params.slug)
  // },
  // watch: {
  //   $route: {
  //     deep: false,

  //     handler () {
  //       this.loadPublication(this.$route.params.slug)
  //     }
  //   }
  // },
  methods: {
    ...mapMutations({
      setBreadcrumb: "SET_BREADCRUMB",
    }),
    loadPublication(slug) {
      this.$route.meta.breadcrumb = "";
      axios
        .get(
          process.env.VUE_APP_API_ADMIN + "api/v1" + "/publication/slug/" + slug
        )
        .then((response) => {
          const content = response.data.data.content;
          this.newsVideo = content[0].body.blocks[0].data.embed;
          this.newsPreview = response.data.data.preview.image_desktop; // Превью
          if (content.length === 1) {
            for (let i = 0; i < content.length; i++) {
              this.newsTitle = content[i].title; // Заголовок новости
              this.newsBlocks = content[i].body.blocks; // Массив с блоками
            }
          }
          this.setBreadcrumb(this.newsTitle);
          this.newsTime = response.data.data.published_at; // Дата публикации

          const description = this.newsBlocks[0].data.text;

          this.$setMeta({
            title: this.newsTitle,
            description: description ? `${description.slice(0, 240)}...` : "",
            image: this.newsPreview,
          });
        })
        .catch(() => {
          this.$router.push({ name: '404' });
          // this.$router.push({ name: 'NewsCatalogPage' }) // redirect to news catalog page
        })
        .finally(() => {
          // this.setLoading(false)
        });
    },
  },
};
</script>
<style lang="scss">
.inner-news__content-wrapper {
  overflow: hidden;
  width: 100%;
  padding: 15px;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (min-width: $screen-lg) {
    padding: 15px;
    width: 1200px;
  }
}
.inner-news-page {
  .page-header--no-padding {
    .content-wrapper {
      padding-bottom: 0;
    }
    .page-title__header {
      margin: 0;
    }
  }

  &__header {
    .breadcrumbs {
      margin-bottom: 25px;
    }
  }
  .inner-news__content img {
    margin-right: 9px;
  }

  img {
    width: 100%;
  }
  iframe {
    width: 100%;
    max-height: 655px;
    height: 50vw;
    border: none;
    @media screen and (min-width: $screen-sm) {
      height: 44vw;
    }
  }
}
</style>
