var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inner-news-page" },
    [
      _c("page-header", {
        staticClass: "inner-news-page__header",
        attrs: {
          light: "",
          "no-padding": "",
          "dark-crumb": "",
          title: _vm.newsTitle || "",
          "link-to": _vm.linkTo,
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [_c("ui-formate-date", { attrs: { date: _vm.newsTime } })]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.newsVideo
        ? _c("content-wrapper-block", [
            _c("iframe", { attrs: { src: _vm.newsVideo, align: "left" } }, [
              _vm._v(" Ваш браузер не поддерживает плавающие фреймы! "),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "inner-news__content-wrapper" }, [
        _c("img", { attrs: { src: _vm.newsPreview } }),
      ]),
      _c("inner-news-render-block", { attrs: { blocks: _vm.newsBlocks } }),
      _c("best-apartments-block"),
      _c("slider-news", {
        attrs: { title: "Похожие новости", navigation: true },
      }),
      _c("callback-block", {
        attrs: { id: "callback", "id-formblock": "question-block__callback" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }