/**
 *  Заменяет путь http://localhost на то что process.env.VUE_APP_API_ADMIN
 *  Если сервер внезапно отдает пути с localhost
 //TODO: Проверять http://localhost/ или https://localhost/ отдельно протокол отдельно пути
 //TODO: Возможно добавление приверки только https process.env.VUE_APP_HTTPS_ONLY
 */
export default function replLocalToApi(str) {
  return str
    .replace("http://localhost/", process.env.VUE_APP_API_ADMIN)
    .replace("http://localhost:8080/", process.env.VUE_APP_API_ADMIN)
    .replace("http://127.0.0.1:10510/", process.env.VUE_APP_API_ADMIN);
}
