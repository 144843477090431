<template>
  <div class="pub-quote">
    <div class="pub-quote__text">
      {{ quoteData.text }}
    </div>
    <div class="pub-quote__caption">
      {{ quoteData.caption }}
    </div>
  </div>
</template>

<script>
export default {
  name: "UIQuote",
  props: {
    /**
     // TODO: added alignment
     {
        "type": "quote",
        "data": {
          "text": String,
          "caption": String,
          "alignment": String
        }
      }
     */
    type: {
      type: String,
      default: "quote",
      required: true,
    },
    quoteData: {
      type: Object,
      default: function () {
        return {
          text: null,
          caption: null,
          alignment: "left",
        };
      },
      required: true,
    },
  },
};
</script>

<style lang="scss">
.pub-quote {
  border: 1px solid rgba(#000, 0.2);
  width: 70%; // magic number
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  margin: 30px 0;
  &__text {
    display: flex;
    font-family: "Bebas Neue", Tahoma, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 130%;
    letter-spacing: 0.05em;
    color: #18181b;
    padding: 30px;
    &:before {
      content: "";
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../../assets/images/icons/icon-quote.svg") center
        no-repeat;
      width: 55px;
      height: 54px;
      margin-right: 30px;
      // TODO: image quote
    }
  }
  &__caption {
    // TODO: design caption
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: rgba(#000, 0.2);
  }
}
</style>
